$brand-white: #ffffff;
$primary-color: #0c4e9c;
$lightblue-color: #e9f3f8;
$red: #f13b1f;
$lightred-color: #fff1f0;
$darkred-color: #df473c;
$dark-color: #333840;
$grey-color: #7f8da1;
$lightGrey-color: #f6f6f6;
$mediumGrey-color: #9e9e9e;
$tableGrey-color: #e0e0e0;
$cardRed-color: #fbeaef;
$card-dark-red: #ae3c60;
$card-light-red: #f9f5eb;
$card-blue-dark: #e9f3f8;
$card-inner-blue: #255e79;
$card-green: #e9f7f8;
$card-green-inner: #267778;
$card-seagreen: #ebf9f1;
$card-seagreen-inner: #82bba0;
$card-yellow: #f3c33c;

.imageflex {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
}
.crosbie{
    max-height: 69vh;
    overflow-y: auto;
    overflow-x: auto;
    padding:0px
}

@media(max-width: 768px){
    .crosbie{
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: auto;
        padding:0px;
    }
}

.cardflex {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4px rgb(212, 209, 209);
    padding: 1rem;
}

.imageplaceholder {
    opacity: 0.4;
}
.uploadWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

// settings button hidden css

.layout-config .layout-config-button {
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    line-height: 52px;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    text-align: center;
    top: 230px;
    left: -52px;
    z-index: -1;
    display: none;
    overflow: hidden;
    cursor: pointer;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    transition: background-color 0.2s;
}

.css-79elbk {
    position: relative;
    background: $brand-white;
}

.search_div {
    display: flex;

    .Input__SearchRound {
        width: 30rem;
        border-radius: 5px 0px 0px 5px;
    }

    .Btn_Go {
        border-radius: 0px 5px 5px 0px;
        background-color: $primary-color;

        &:hover {
            background-color: $primary-color;
        }
    }
}

.p-overlay-badge .p-badge {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    margin: 0;
    background: $red;
}

.user_info {
    display: flex;
    flex-direction: row;
    align-items: center;

    > img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .p_div {
        .p1 {
            font-size: 14px;
            line-height: 17px;
            font-style: normal;
            font-weight: 600;
            color: $dark-color;
            margin-bottom: 0px;
        }

        .p2 {
            margin-top: 0px;
            color: $grey-color;
            font-size: 14px;
            line-height: 17px;
            font-style: normal;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
}

// Filter Icon Removed

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
    display: none;
}

.Elipse_Icon {
    background-color: $lightGrey-color;
    border: none;
    color: $mediumGrey-color;
    cursor: pointer;
}

// datatable css

.p-datatable-thead {
    height: 48px;
    width: 100%;
}
// .p-fluid .p-inputtext {
//     width: 100%;
//     height: 31px;
// }
.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    transition: box-shadow 0.2s;
    white-space: pre;
    background: $tableGrey-color;
    color: $primary-color;
}

//breadcrumb heading css

.p-breadcrumb {
    background: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 1rem;
}

// page header

.Page__Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.Top__Btn {
    width: 50%;
    display: flex;
    justify-content: flex-end;

    .Btn__DarkAdd {
        width: 92px !important;
        height: 36px !important;
        background-color: $lightblue-color;
        border: 1px solid $primary-color;
        color: $primary-color;

        &:hover {
            width: 92px !important;
            height: 36px !important;
            background-color: $lightblue-color;
            border: 1px solid $primary-color;
            color: $primary-color;
        }
    }
    .Btn__DarkDelete {
        width: 36px !important;
        height: 36px !important;
        margin-left: 20px;
        border: 1px solid $darkred-color;
        color: $darkred-color;
        background-color: $lightred-color;

        &:hover {
            width: 36px !important;
            height: 36px !important;
            margin-left: 20px;
            border: 1px solid $darkred-color;
            color: $darkred-color;
            background-color: $lightred-color;
        }
    }
    .Btn__Blue {
        width: 36px !important;
        height: 36px !important;
        margin-left: 20px;
        border: 1px solid blue;
        color: white;
        background-color: blue;

        &:hover {
            width: 36px !important;
            height: 36px !important;
            margin-left: 20px;
            border: 1px solid $darkred-color;
            color: $darkred-color;
            background-color: $lightred-color;
        }
    }
}

.layout-theme-light {
    background-color: $brand-white;
}

//dashboard sass styles

.grid_view {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .card1 {
        width: 162px;
        height: 208px;
        border-radius: 5px;
        background: $cardRed-color;
        .home_icon {
            display: flex;
            justify-content: center;
            font-size: 30px;
            margin-top: 30px;
            color: $card-dark-red;
        }

        > p:nth-child(2) {
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            margin-top: 10px;
            color: $card-dark-red;
        }
        > p:nth-child(3) {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
        }
    }
    .card2 {
        width: 162px;
        height: 208px;
        border-radius: 5px;
        background: $lightred-color;
        .home_icon {
            display: flex;
            justify-content: center;
            font-size: 30px;
            margin-top: 30px;
            color: $darkred-color;
        }

        > p:nth-child(2) {
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            margin-top: 10px;
            color: $darkred-color;
        }
        > p:nth-child(3) {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
        }
    }
    .card3 {
        width: 162px;
        height: 208px;
        border-radius: 5px;
        background: $card-light-red;
        .home_icon {
            display: flex;
            justify-content: center;
            font-size: 30px;
            margin-top: 30px;
            color: $card-yellow;
        }

        > p:nth-child(2) {
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            margin-top: 10px;
            color: $card-yellow;
        }
        > p:nth-child(3) {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
        }
    }
    .card4 {
        width: 162px;
        height: 208px;
        border-radius: 5px;
        background: $lightblue-color;
        .home_icon {
            display: flex;
            justify-content: center;
            font-size: 30px;
            margin-top: 30px;
            color: $card-inner-blue;
        }

        > p:nth-child(2) {
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            margin-top: 10px;
            color: $card-inner-blue;
        }
        > p:nth-child(3) {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
        }
    }
    .card5 {
        width: 162px;
        height: 208px;
        border-radius: 5px;
        background: $card-green;
        .home_icon {
            display: flex;
            justify-content: center;
            font-size: 30px;
            margin-top: 30px;
            color: $card-green-inner;
        }

        > p:nth-child(2) {
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            margin-top: 10px;
            color: $card-green-inner;
        }
        > p:nth-child(3) {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
        }
    }
    .card6 {
        width: 162px;
        height: 208px;
        border-radius: 5px;
        background: $card-seagreen;
        .home_icon {
            display: flex;
            justify-content: center;
            font-size: 30px;
            margin-top: 30px;
            color: $card-seagreen-inner;
        }

        > p:nth-child(2) {
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            margin-top: 10px;
            color: $card-seagreen-inner;
        }
        > p:nth-child(3) {
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
        }
    }
}

// chart css

.buttonsaaa{
   
   border-radius: 6px;
   cursor: pointer;
   
//    height: 40px;
}
.download-button{
   padding: 0px !important;
   width: 36px !important;
        height: 36px !important;
        margin-left: 20px;
        border: 1px solid blue;
        color: white;
        background-color: blue;
        background: blue !important;

        &:hover {
            width: 36px !important;
            height: 36px !important;
            margin-left: 20px;
            border: 1px solid $darkred-color;
            color: $darkred-color;
            background-color: $lightred-color;
        }
}


.graphs_section {
    width: 100%;
    height: 410px;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .left_ {
        width: 100%;
        height: 100%;
    }
    .right {
        width: 30%;
        height: 100%;
    }
}

.p-chart {
    position: relative;
    height: 310px;
}

//status button

.status_button {
    width: 90px !important;
    height: 30px !important;
    color: #269e51;
    background-color: #ebf9f0;
    border: none;
    cursor: pointer;
    justify-content: center;
    font-size: 11px;
    &:hover {
        width: 71px !important;
        height: 30px !important;
        color: #269e51 !important;
        background-color: #ebf9f0 !important;
        border: none !important;
    }
}
//manage page css

.grid_view {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 40px;
    row-gap: 20px;
    column-gap: 35px;
    .manage_card {
        width: 260px;
        height: 108px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        background: radial-gradient(95.9% 212.5% at 4.1% 0%, rgba(12, 78, 156, 0.68) 0%, #0c4e9c 74.49%);
        cursor: pointer;
    }
}

.Down__Btn {
    text-align: end;

    @media (max-width: 390px) {
        display: flex;
    }

    .Btn__Transparent {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $brand-white;
        color: $primary-color;
        cursor: pointer;
        width: 150px;
        border: none;
        // font-family: $titleTextFont;
        padding-right: 5px;

        &:hover,
        :active,
        :focus {
            background-color: $primary-color;
            color: $brand-white;
        }
        .p-button-label {
            font-weight: 300;
        }
    }

    .Btn__Dark {
        font-size: 16px;
        height: 40px;
        font-weight: 300;
        background-color: $primary-color;
        color: $brand-white;
        border: 1px solid $primary-color;
        border-radius: 5px;
        cursor: pointer;
        width: 180px;
        margin-left: 0px;
        margin-top: 10px;

        &:hover,
        :active,
        :focus {
            background-color: $brand-white;
            color: $primary-color;
            border: 1px solid $primary-color;
        }
        .p-button-label {
            font-weight: 300;
            white-space: nowrap;
        }
    }
}

//tyre profile inputs css

.description_area_inputs{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .__left{
        width: 48%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .__right{
        width: 48%;
        height: auto;
        display: flex;
        flex-direction: column;
    }
}

//single user header css

.customer_header__ {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    .grey__button {
        background: #f6f6f6;
        color: #71747a;
        border: 1px solid #71747a;
        margin-left: 15px;
    }
    .grey__button:hover {
        background: #f6f6f6;
        color: #71747a;
        border: 1px solid #71747a;
        margin-left: 15px;
    }
}
.customer_details_section {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .left_section {
        width: 20%;
        height: auto;
        > img {
            width: 100%;
            height: 225px;
            border-radius: 5px;
        }
        .id_section {
            width: 100%;
            height: 60px;
            border-radius: 5px;
            background: #f6f6f6;
            margin-top: 10px;
            display: flex;
            align-items: center;
            > div:nth-child(1) {
                width: 50%;
                height: 30px;
                border-right: 1px solid #aeaeae;
                padding-left: 10px;
                > p:nth-child(1) {
                    font-size: 14px;
                    margin-bottom: 0px;
                    color: #000;
                }
                > p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                }
            }
            > div:nth-child(2) {
                width: 50%;
                display: flex;
                justify-content: center;
                .green_btn {
                    height: 30px;
                    background: #269e51;
                    border-radius: 16px;
                    border: none;
                }
                .green_btn:hover {
                    height: 30px;
                    background: #269e51;
                    border-radius: 16px;
                    border: none;
                }
            }
        }
    }
    .right_section {
        width: 100%;
        height: auto;
        border: 1px solid $tableGrey-color;
        border-radius: 5px;
        padding: 10px;
        .form__ {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 60px;
            .form_left {
                width: 48%;
                height: auto;
                
                > div {
                    width: 100%;
                    margin-top: 0px;
                    display: flex;
                    flex-direction: column !important;
                    .Input__Round{
                        width: 100% !important;
                        margin-top: 10px;
                    }
                }
            }
            .form_right {
                width: 48%;
                height: auto;
                > div {
                    width: 100%;
                    margin-top: 0px;
                    display: flex;
                    flex-direction: column !important;
                    .Input__Round{
                        width: 100% !important;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.Input__Round {
    height: 31px;
    width: 100% !important;
}

.Input__RoundFile {
    height: 40px !important;
}

.p-inputtext:enabled:hover {
    border: 1px solid #ced4da !important;
}

.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

// Radio Button CSS
.field___{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.Radio__Btn {
    display: flex;
    margin-right: auto;

    .p-field-radiobutton {
        width: 50%;
        margin-left: 5px;
        height: 30px;
        padding-left: 5px;
        display: flex;
        align-items: center;

    }
    label {
        margin-left: 10px;
        margin-top: 5px;
    }
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    margin-top: 4px;
}
//brand page css

.selection_area {
    width: 300px;
    height: 40px;
    display: flex;
    border: 1px solid $tableGrey-color;
    border-radius: 5px;
    margin-top: 50px;
    padding: 2px 7px;
    .selected {
        width: 50%;
        height: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid $primary-color;
        cursor: pointer;
    }
    .unselected {
        width: 50%;
        height: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
.des_div {
    width: 100%;
    height: 400px;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
}
.features_div {
    width: 100%;
    height: auto;
    border: 1px solid $tableGrey-color;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
}
.cards__div {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    > div {
        width: 22%;
        height: 200px;
        border-radius: 5px;
        background: $tableGrey-color;
        padding: 10px;
        > p:nth-child(2) {
            margin-top: 40px;
            font-size: 60px;
            text-align: center;
            margin-bottom: 0px;
        }
        > p:nth-child(3) {
            font-size: 18px;
            text-align: center;
            margin-top: 0px;
        }
    }
}
.cards__div2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    > div {
        width: 22%;
        height: 200px;
        border-radius: 5px;
        background: $tableGrey-color;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        >input:nth-child(1)
        {
            margin-left: -200px;
        }
        > img:nth-child(2) {
            margin-top: 20px;
            font-size: 60px;
            text-align: center;
            margin-bottom: 0px;
        }
        > p:nth-child(3) {
            font-size: 18px;
            text-align: center;
            margin-top: 10px;
        }
    }
}

// text area CSS

.TextArea__Round {
    display: block;
    width: 100%;
    padding: 0.375rem 1rem !important;
    font-size: 0.81rem;
    line-height: 2;
    background-clip: padding-box;
    border: 0;
    border: 1px solid #ced4da;
}

.p-fluid .p-inputtextarea {
    width: 100%;
    height: 100px;
}

.p-inputtext {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: none !important;
}
.details_div__{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .tyre_left{
        width: 28%;
        height: auto;
        .badge_div{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .__card{
                width: 48%;
                height: 225px;
                background: $tableGrey-color;
                border-radius: 5px;
                padding: 4px;
                >div:nth-child(1){
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    height: 80%;
                }
                >div:nth-child(2){
                    width: 100%;
                    height: 15%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 15px;
                    >p{
                        font-size: 30px;
                        margin-top: -10px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .id_section {
            width: 100%;
            height: 60px;
            border-radius: 5px;
            background: #f6f6f6;
            margin-top: 10px;
            display: flex;
            align-items: center;
            > div:nth-child(1) {
                width: 50%;
                height: 30px;
                border-right: 1px solid #aeaeae;
                padding-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                > p:nth-child(1) {
                    font-size: 14px;
                    margin-bottom: 0px;
                    color: #000;
                }
                > p:nth-child(2) {
                    font-size: 14px;
                    font-weight: 600;
                    color: #000;
                }
            }
            > div:nth-child(2) {
                width: 50%;
                display: flex;
                justify-content: center;
                .green_btn {
                    height: 30px;
                    background: #269e51;
                    border-radius: 16px;
                    border: none;
                }
                .green_btn:hover {
                    height: 30px;
                    background: #269e51;
                    border-radius: 16px;
                    border: none;
                }
            }
        }
    }
    .tyre_right{
        width: 70%;
        height: auto;
        border: 1px solid $tableGrey-color;
        border-radius: 5px;
        padding: 10px;
        >div:nth-child(1){
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            >p{
                font-size: 20px;
                color: #000;
                font-weight: 600;
            }
            .grey_button{
                background:#F6F6F6 ;
                border: none;
                color: #71747A;
            }
        }
        .right_section {
            width: 100%;
            height: auto;
            border-radius: 5px;
            padding: 10px;
            .form__ {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 60px;
                .form_left {
                    width: 48%;
                    height: auto;
                    > div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 10px;
                        > input {
                            width: 60%;
                        }
                    }
                }
                .form_right {
                    width: 48%;
                    height: auto;
                    > div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 10px;
                        > input {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}

.row__{
    width: 100%;
    height: 40px;
    display: flex;
    .col_left{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
        border: 1px solid $grey-color;
        >p:nth-child(1){
            font-size: 16px;
            margin-top: 10px;
            text-transform: capitalize;
        }
        >p:nth-child(2){
            font-size: 16px;
            color: #000;
            text-transform: capitalize;
            font-weight: 600;
        }
    }
    .col_right{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
        border: 1px solid $grey-color;
        >p:nth-child(1){
            font-size: 16px;
            margin-top: 10px;
            text-transform: capitalize;
        }
        >p:nth-child(2){
            font-size: 16px;
            color: #000;
            text-transform: capitalize;
            font-weight: 600;
        }
    }
}

.Dropdown__Round{
    width: 220px;
}
.select__{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #E5E4E2;
}
.select{
    width: 160px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #E5E4E2;
}
.pink_button{
    height: 30px;
    border-radius: 20px;
    background: #F9F5EB;
    color: #F0AD4E;
    border: none;
}
.pink_button:hover{
    height: 30px;
    border-radius: 20px;
    background: #F9F5EB;
    color: #F0AD4E;
    border: none;
}
.sub_header___{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 20px;
}
._btn_{
    width: 160px;
    height: 53px;
    border: 1px solid $grey-color;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    >div{
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 4px solid $primary-color;
        >p{
            color: #000;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.input_section{
    width: 100%;
    height: auto;
    border: 1px solid $grey-color;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
}
.data_section{
    width: 100%;
    display: flex;
}
.left_section{
    width: 49%;
    padding: 10px;
    >div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        >p{
            margin-top: 7px;
        }
    }
}
.right_section{
    width: 49%;
    padding: 10px;
    >div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        >p{
            margin-top: 7px;
        }
    }
}

.inputs_div__{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    >div{
        width: 30%;
        height: auto;
        display: flex;
        flex-direction: column;
        .Dropdown__Rounddd{
            width: 100%;
            margin-top: 10px;
        }
    }
}
.last_btn_div__{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .Btn__DarkAdd {
        height: 36px !important;
        background-color: $lightblue-color;
        border: 1px solid $primary-color;
        color: $primary-color;
    
        &:hover {
            height: 36px !important;
            background-color: $lightblue-color;
            border: 1px solid $primary-color;
            color: $primary-color;
        }
    }
}
.description_area{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .left__{
        width: 48%;
        height: auto;
    }
    .right__{
        width: 48%;
        height: auto;
    }
}
._inputs_div__{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    >div{
        width: 45%;
        height: auto;
        display: flex;
        flex-direction: column;
        .Dropdown__Rounddd{
            width: 100%;
            margin-top: 10px;
        }
    } 
}


.dropdwon__header_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card .row-notes{
    background-color: rgb(204, 200, 200) !important;
}
.card .row-invoice{
    background-color: rgb(7, 223, 7) !important;
}
.card .row-status{
    background-color: rgb(25, 223, 25) !important;
}


.package_manifest{
    background-color: #f6f6f6;
    padding:10px;
    border-radius: 10px;
    margin-bottom: 20px;
    >p:nth-child(1)
    {
        font-size: 20px;
        font-weight: 600;
        margin-top: 10px;
    }
    .manifest-inputDiv{
        display: flex;
        column-gap: 20px;
        >div{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            width: 200px;
            >input{
                width: 100%;
                height: 30px;
                border-radius: 6px;
                border: 1px solid #cecece;
                padding: 5px;
            }
        }
    }
    .manifest-description{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
    }
    .manifestButton{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        >button{
            border-radius: 6px;
            border: none;
            background-color: #007bff;
            color: white;
            padding: 10px 100px;
        }
    }
}
