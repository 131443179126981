
.container_payment {
  background-color: #FFFFFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 0 6px 0 rgba(141,151,158,0.2);
  padding: 24px;
}

.form-row_payment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.field_payment {
  box-sizing: border-box;
  border: 1px solid #DEE0E1;
  border-radius: 3px;
  height: 55px;
  margin-bottom: 30px;
  padding: 14px;
  width: 100%;
}

.button-container_payment{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button_payment{
  background-color: #228800;
  border: none;
  border-radius: 3px;
  color: #FFFFFF;
  display: block;
  height: 47px;
  width: 300px;
}
/* body {
    background-color: #192130;
    margin: 0 auto;
    width: 300px;
    font-family: Roboto, "Open Sans", sans-serif; 
  } */
  
  /* 
  .container * {
    font-size: 16px;
  }
  
  
  
  .container .form-row.top-row {
    margin-top: 16px;
  }
  
  .input-errors {
    font-size: 12px;
    position: absolute;
    margin-left: 5px;
    margin-top: 54px;
    color: red;
  }
  

  
  
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
    button {
    background-color: #228800;
    border: none;
    border-radius: 3px;
    color: #FFFFFF;
    display: block;
    height: 47px;
    width: 300px;
  }
  
  #payment-request-button {
    width: 85%;
    height: 40px;
    margin: 0 auto;
  }
  
  @media (min-width: 300px) {
    body {
      width: auto;
    }
  }
  
  .hr {
    width: 100%; 
    height: 10px; 
    border-bottom: 1px solid black; 
    text-align: center;
    margin: 20px 0;
  }
  
  .hr span {
    font-size: 10px; 
    background-color: #FFF; 
    padding: 0 10px;
  }
  
  .clover-privacy-link {
    font-family: Roboto, "Open Sans", sans-serif;
  } */