.container{
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    .inputDiv{
        display: flex;
        flex-direction: column;
        >label{
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        >input{
            border-radius: 10px;
            border: 1px solid #d3d3d3;
            margin-bottom: 10px;
            width: 30%;
            height: 35px;
        }
    }
    .reportDiv{
        padding: 10px;
        .reportInner{
            margin-top: 10px;
            >p{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
                color: black;
            }
            .mainReport{
                display: flex;
                flex-wrap: wrap;
                column-gap: 20px;
                padding: 10px;
                >p{
                    padding: 9px 10px;
                    background-color: white;
                    border-radius: 10px;
                    font-weight: 500;
                    color: black;
                    font-size: 15px;
                }
                >p:last-child{
                    display: none;
                }
            }
            .pendingReport{
                // background-color: white;
                padding: 10px;
                border-radius: 10px;
                // color: white;
                margin-bottom: 10px;
                border-bottom: 1px solid #cecece;
                .bold{
                    font-weight: bold;
                }
                .topDiv{
                    display: flex;
                    >div:nth-child(2)
                    {
                        margin-left: auto;
                        display: flex;
                        column-gap: 10px;
                       
                        >p:nth-child(1)
                        {
                            padding: 5px;
                            background-color: red;
                            border-radius: 10px;
                            color: white;
                        }
                       
                    }
                }
                .invoicesDiv{
                   
                    >div{
                        background-color: white;
                        margin-top: 10px;
                        padding: 10px;
                        border-radius: 10px;
                       >p:nth-child(1)
                       {
                            font-weight: bold;
                       }
                    }
                }
            }
        }
    }
}

@media(max-width:1024px)
{
    .container{
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 10px;
        .inputDiv{
            display: flex;
            flex-direction: column;
            >label{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            >input{
                border-radius: 10px;
                border: 1px solid #d3d3d3;
                margin-bottom: 10px;
                width: 100%;
                height: 35px;
            }
        }
        .reportDiv{
            padding: 10px;
            .reportInner{
                margin-top: 10px;
                >p{
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    color: black;
                }
                .mainReport{
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 20px;
                    padding: 10px;
                    >p{
                        padding: 9px 10px;
                        background-color: white;
                        border-radius: 10px;
                        font-weight: 500;
                        color: black;
                        font-size: 15px;
                        width: 100%;
                    }
                    >p:last-child{
                        display: none;
                    }
                }
                .pendingReport{
                    // background-color: white;
                    padding: 10px;
                    border-radius: 10px;
                    // color: white;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #cecece;
                    .bold{
                        font-weight: bold;
                    }
                    .topDiv{
                        display: flex;
                        flex-direction: column;
                        >div:nth-child(2)
                        {
                            margin-left: auto;
                            display: flex;
                            column-gap: 10px;
                           
                            >p:nth-child(1)
                            {
                                padding: 5px;
                                background-color: red;
                                border-radius: 10px;
                                color: white;
                            }
                           
                        }
                    }
                    .invoicesDiv{
                       
                        >div{
                            background-color: white;
                            margin-top: 10px;
                            padding: 10px;
                            border-radius: 10px;
                           >p:nth-child(1)
                           {
                                font-weight: bold;
                           }
                        }
                    }
                }
            }
        }
    }
}