.deliveries_top {
    width: 100%;

    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    > div {
        width: 25%;
        display: flex;
        flex-direction: column;
        > label {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
            text-align: start;
            margin-top: 10px;
        }

        > input {
            height: 35px;
            border-radius: 10px;
            padding-left: 10px;
        }
        > select {
            height: 35px;
            border-radius: 10px;
            padding-left: 10px;
        }
        > p {
            border: 1px solid #cecece;
            padding: 7px 50px;
            border-radius: 10px;
            padding-left: 10px;
        }
    }
}

.custom-background-color {
    background-color: #ffcccb; /* Replace with your desired background color */
}
.deliveries_bottom {
    width: 100%;
    border: 1px solid #cecece;
    display: flex;
    padding: 20px;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    > div {
        width: 45%;
        display: flex;
        flex-direction: column;
        > label {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
            text-align: start;
            margin-top: 10px;
        }

        > input {
            height: 35px;
            border-radius: 10px;
            padding-left: 10px;
        }
        > textarea {
            height: 100px;
            border-radius: 10px;
            padding: 10px;
        }
        > div {
            border: 1px solid #cecece;
        }
    }
    > button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        background-color: red;
        color: white;
        border-radius: 10px;
        font-size: 18px;
        cursor: pointer;
    }
    .bottom_check {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        margin-top: 10px;
        > input {
            height: 20px;
            border-radius: 10px;
            padding-left: 10px;
            margin-top: 10px;
        }
        > label {
            font-size: 16px;
        }
    }
    > ul {
        margin-top: -10px;
    }
    .submit__button {
        padding: 10px 20px;
        margin-top: 10px;
        border: none;
        background-color: green;
        color: white;
        border-radius: 10px;
        font-size: 18px;
        cursor: pointer;
    }
}

.deliveries_bottom2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 30px;
    padding-top: 10px;
    > div:nth-child(1) {
        display: flex;
        > label {
            font-size: 20px;
            font-weight: bold;
        }
        > p {
            font-size: 20px;
            margin-left: 10px;
            margin-top: -3px;
        }
    }
    > div:nth-child(2) {
        display: flex;
        margin-top: 10px;
        > label {
            font-size: 20px;
            font-weight: bold;
        }
        > p {
            font-size: 20px;
            margin-left: 10px;
            margin-top: -3px;
        }
    }
    > div:nth-child(3) {
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;
        width: 300px;
        > p {
            font-size: 18px;
            margin-left: 10px;
        }
    }
    > div:nth-child(1) {
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;
        width: 300px;
        > img {
            object-fit: cover;
            width: 40%;
        }
    }
}
.Headerit {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
}

.invoice_innerss {
    padding: 20px;
    border: 1px solid #cecece;
}
.innr_titleee {
    font-size: 18px;
    color: black;
}

.packages_divvv {
    display: flex;
    justify-content: space-between;
}
.bottom_ittsss {
    display: flex;
    justify-content: flex-end;
}
