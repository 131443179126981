.container{
    padding: 10px;
    .buttonDiv{
        display: flex;
        column-gap: 10px;
        flex-wrap: wrap;
        row-gap: 10px;
        background-color: #d3d3d3;
        padding: 20px;
        border-radius: 10px;
        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        >button{
            border: none;
            background-color: blue;
            padding: 10px 20px;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            &:hover{
                background-color: #1e90ff;
            }
        }
        >button:nth-child(2)
        {
            background-color: #ff4500;
            &:hover{
                background-color: #ff6347;
            }
        }
        >button:nth-child(3)
        {
            background-color: #008000;
            &:hover{
                background-color: #32cd32;
            }
        }
        >button:nth-child(4)
        {
            background-color: #ff8c00;
            &:hover{
                background-color: #ffa500;
            }
        }
        >button:nth-child(5)
        {
            background-color: #8a2be2;
            &:hover{
                background-color: #9932cc;
            }
        }
    }
    .title{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}