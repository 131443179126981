.label__main {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    .label_inner {
        width: 50%;    
        background-color: rgb(247, 244, 244);
        padding: 10px;
        .label_top {
            width: 100%;
            height: 100px;
            border-bottom: 3px solid black;
            display: flex;
            > div:nth-child(1) {
                width: 30%;
                height: 100%;
                border-right: 3px solid black;
                display: flex;
                justify-content: center;
                align-items: center;
                > h1 {
                    font-size: 55px;
                }
            }
            > div:nth-child(2) {
                width: 70%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                > h1 {
                    font-size: 50px;
                }
            }
        }
        .label_middle {
            width: 100%;
            border-bottom: 3px solid black;
            padding: 20px 30px;
            > p:nth-child(1) {
                font-size: 20px;
                font-weight: 600;
            }
            > p:nth-child(2) {
                margin-left: 70px;
                margin-top: -10px;
                font-size: 18px;
            }
            .label__name {
                margin-left: 70px;
                font-size: 18px;
                font-weight: 600;
                margin-top: -10px;
            }
        }
        .label_middle2 {
            width: 100%;
            height: 100px;
            border-bottom: 1px solid black;
            display: flex;

            > div:nth-child(1) {
                width: 37%;
                height: 100%;
                border-right: 1px solid black;
                display: flex;
                justify-content: center;
                align-items: center;

                > p {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                }
            }

            > div:nth-child(2) {
                width: 63%;
                height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;

                > h1 {
                    font-size: 35px;
                    margin-top: 10px;
                }
            }
        }
        .label_middle3 {
            width: 100%;     
            border-bottom: 1px solid black;
            display: flex;
            > div:nth-child(1) {
                width: 50%;
                height: 100%;
                border-right: 1px solid black;
                padding: 10px 15px;
                >p{
                    font-size: 20px;
                    font-weight: 400;
                }
            }
            > div:nth-child(2) {
                width: 50%;
                height: 100%;
               padding: 10px 15px;
               >p{
                     font-size: 20px;
                     font-weight: 400;
               }
            }
        }
        .label_bottom {
            width: 100%;     
            display: flex;
            > div:nth-child(1) {
                width: 37%;
                height: 100%;
                border-right: 1px solid black;
                padding: 10px 15px;
                >p{
                    font-size: 40px;
                    font-weight: 600;
                }
            }
            > div:nth-child(2) {
                width: 63%;
                height: 100%;
                display: flex;
                justify-content: center;
              
            }
        }
    }
}
.buttons_main{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    padding-bottom: 40px;
    >button:nth-child(1)
    {
        padding: 10px 40px;
        background-color: red;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        cursor: pointer;
    }
    >button:nth-child(2)
    {
        padding: 10px 50px;
        background-color: green;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 18px;
        cursor: pointer;
    }
}