.p-datatable-loading-overlay{
   background-color: none !important;
} 
.p-component-overlay{
  background-color: transparent !important;
}

.p-datatable-wrapper {
    overflow-x: auto;
  }
  
  .p-datatable-scrollable-wrapper {
    overflow-x: hidden;
  }
  
  .p-datatable-scrollable-body {
    overflow-y: auto;
  }

  .custom-scrollbar-wrapper {
    position: relative;
   
  }
  
  .custom-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
  }
  
  .custom-scrollbar-content {
    display: inline-block;
  }
  .custom-scrollbar::-webkit-scrollbar {
    height: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #007BFF; /* Change the color to your preferred color */
    border-radius: 4px;
  }

  .custom-datatable-wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  .custom-scrollbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid red;
  }
  
  .p-datatable-scrollable-header-box,
  .p-datatable-scrollable-footer-box {
    overflow-x: hidden;
  }