.Headerit {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
}
.iunvoice{
    position: relative;
    width: 900px;
}

.invoice_Main {
    width: 100%;
    margin-top: 20px;
    padding: 20px 50px;
    .invoice_top {
        .Billing_section {
            width: 100%; 
            margin-top: 25px;
            display: flex;
            padding: 5px;
            column-gap: 130px;
            .billing_section_1 {
                > p:nth-child(1) {
                    font-size: 18px;
                    font-weight: 600;
                    color: black;
                }
                > span {
                    font-size: 14px;
                    font-weight: 500;
                    color: black;
                }
            }
        }
    }
    .receipt_title{
        font-size: 24px;
        font-weight: bold;
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: 50px;
    }
    .invoice_receipt{
        width: 100%;
        border-top: 4px solid black;
        border-bottom: 4px solid black;
        
        .receipt_div{
            height: 40px;
            border-bottom: 4px solid rgb(196, 194, 194);
            display: flex;
            align-items: center;
            column-gap: 100px;
            >p:nth-child(1){
                font-size: 16px;
                color: black;
                font-weight: 500;         
                padding-top: 14px;
                width: 140px;
            }
            >p:nth-child(2){
                font-size: 16px;
                color: black;
                font-weight: 500;         
                
            }
        }
        .invoice_table{
            margin-top: 60px;
            .inside_table{
                width: 100%;
                border-collapse: collapse;
                .inside_head{
                    text-align: left;
                    color: black;
                }
                .inside_table_body{
                    .inside_row{
                        border-bottom: 4px solid rgb(196, 194, 194) !important;
                        >td{
                            padding: 15px 0px;
                            font-size: 16px;
                            color: black;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .invoice_bottom_title{
        font-size: 18px;
        margin-top: 50px;
        font-family: Arial, Helvetica, sans-serif;
        color: black;
        font-weight: bold;
    }
    .invoice_next{
        color: black;
        margin-top: -10px;
    }
    .invoice_next1{
        color: black;
       
    }
    .invoice_next2{
        color: black;
       margin-top: 50px;
       font-size: 12px;
    }
    .invoice_li{
        font-size: 16px;
        color: black;
        line-height: 24px;
    }
    .invoice_input{
        width: 50px;
        height: 30px;
        padding-left: 5px;
        border-radius: 6px;
    }
}

.invoice_notes{
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
    >p{
        font-size: 16px;
        color: black;
        font-weight: 500;
    }
    .invoice_testArea{
        width: 70%;
        height: 100px;
        border-radius: 6px;
        border: 1px solid rgb(196, 194, 194);
        padding: 10px;
        font-size: 16px;
        color: black;
        font-weight: 500;
    }

}


@media(max-width:768px)
{
    .Headerit {
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
    }
    .iunvoice{
        position: relative;
        width: 100%;
    }

    .invoice_notes{
        display: flex;
        column-gap: 10px;
        margin-top: 10px;
        >p{
            font-size: 16px;
            color: black;
            font-weight: 500;
        }
        .invoice_testArea{
            width: 100%;
            height: 100px;
            border-radius: 6px;
            border: 1px solid rgb(196, 194, 194);
            padding: 10px;
            font-size: 16px;
            color: black;
            font-weight: 500;
        }
    
    }
    
    .invoice_Main {
        width: 100%;
        margin-top: 20px;
        padding: 20px 0px;
        .invoice_top {
            .Billing_section {
                width: 100%; 
                margin-top: 25px;
                display: flex;
                padding: 5px;
                column-gap: 50px;
                .billing_section_1 {
                    > p:nth-child(1) {
                        font-size: 16px;
                        font-weight: 600;
                        color: black;
                    }
                    > span {
                        font-size: 12px;
                        font-weight: 500;
                        color: black;
                    }
                }
            }
        }
        .receipt_title{
            font-size: 20px;
            font-weight: bold;
            color: black;
            font-family: Arial, Helvetica, sans-serif;
            margin-top: 50px;
        }
        .invoice_receipt{
            width: 100%;
            border-top: 4px solid black;
            border-bottom: 4px solid black;
            
            .receipt_div{
                height: 40px;
                border-bottom: 4px solid rgb(196, 194, 194);
                display: flex;
                align-items: center;
                column-gap: 100px;
                >p:nth-child(1){
                    font-size: 14px;
                    color: black;
                    font-weight: 500;         
                    padding-top: 14px;
                    width: 140px;
                }
                >p:nth-child(2){
                    font-size: 14px;
                    color: black;
                    font-weight: 500;         
                    
                }
            }
            .invoice_table{
                margin-top: 60px;
                .inside_table{
                    width: 100%;
                    border-collapse: collapse;
                    .inside_head{
                        text-align: left;
                        color: black;
                    }
                    .inside_table_body{
                        .inside_row{
                            border-bottom: 4px solid rgb(196, 194, 194) !important;
                            >td{
                                padding: 15px 0px;
                                font-size: 11px;
                                color: black;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
        .invoice_bottom_title{
            font-size: 18px;
            margin-top: 50px;
            font-family: Arial, Helvetica, sans-serif;
            color: black;
            font-weight: bold;
        }
        .invoice_next{
            color: black;
            margin-top: -10px;
        }
        .invoice_next1{
            color: black;
           
        }
        .invoice_next2{
            color: black;
           margin-top: 50px;
           font-size: 12px;
        }
        .invoice_li{
            font-size: 16px;
            color: black;
            line-height: 24px;
        }
        .invoice_input{
            width: 50px;
            height: 30px;
            padding-left: 5px;
            border-radius: 6px;
        }
    }
    
}