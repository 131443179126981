.login_body {
    background: #f6f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    height: 100vh !important;
    margin-top: 0% !important;
}
.logo_h1 {
    color: #066842;
}

.login_h1 {
    font-weight: bold;
    margin: 0;
}

.login_p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

.login_button {
    border-radius: 20px;
    border: 1px solid #2c38a7;
    background-color: #353b74;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    width: 100px;
}

.login_button:active {
    transform: scale(0.95);
}

.login_button:focus {
    outline: none;
}

.login_button.ghost {
    background-color: transparent;
    border-color: #ffffff;
}

.login_form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.login_input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 400px;
}

// .second-container{
   
// }

.mobile_only{
    display: block !important;
}

@media(max-width:768px)
{
    .mobile_only{
        display: block !important;
    }
    .container{
        background-color: #fff;
    border-radius: 10px;
    box-shadow: none !important;
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 400px;
    }
    .second-container{
        display: none !important;
    }
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}


.container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

@media(max-width:768px)
{
    .sign-in-container{
        width: 100% !important;
        left: 0;
    }
}

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

@media(max-width:768px)
{
    .overlay-container{
        display: none !important;
    }
}

.container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    background: #ff416c;
    background: -webkit-linear-gradient(to right, #05163b, #0c136b);
    background: linear-gradient(to right, #1a3694, #443d61);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

.p-mt-2 {
    margin-top: 5px;
}



//new design


.login_container{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 600px;
    max-width: 100%;
    height: 500px;
}

.login_header{
    text-align: start;
    margin-top: 20px;
    font-size: 18px !important;
    font-weight: normal !important;
    font-family: 'Poppins', sans-serif;
    margin-left: 60px;
}

.form_div{
    margin-top: 20px;
    margin-left: 60px;
    margin-right: 60px;
}

@media(max-width:768px)
{
    .login_container{
        background-color: #fff;
        border-radius: 10px;
        box-shadow: none !important;
        position: relative;
        overflow: hidden;
        width: 600px;
        max-width: 100%;
        height: 500px;
    }
    
    .login_header{
        text-align: start;
        margin-top: 20px;
        font-size: 18px !important;
    font-weight: normal !important;
        font-family: 'Poppins', sans-serif;
        margin-left: 20px;
    }
    
    .form_div{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
}
