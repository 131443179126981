.water_mark_main{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(248, 246, 246, 0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    .watermark_title{
        font-size: 70px;
        transform: rotate(-45deg);
        color: gainsboro;
    }
}