.Advance_main{
    >h1:nth-child(1)
    {
        font-size: 1.8rem;
        font-weight: 600;
        margin-bottom: 1rem;
        margin-top: 20px;
    }
    .advance_inner{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 50px;
        column-gap: 20px;
        align-items: center;
        .feature_block{
            width: 22%;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background-color: #82bba0;
            cursor: pointer;
            >p{
                font-size: 1.3rem;
                color: white;
            }
        }
    }
}

@media(max-width:1024px)
{
    .Advance_main{
        >h1:nth-child(1)
        {
            font-size: 1.5rem;
        }
        .advance_inner{
            row-gap: 20px;
            .feature_block{
                width: 45%;
                height: 60px;
                >p{
                    font-size: 1rem;
                }
            }
        }
    }
}